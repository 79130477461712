.billing-manager-banner {
  padding: $spacer-5 $spacer-3;
  margin-bottom: $spacer-5;
  overflow: hidden;
  background: var(--bgColor-muted, var(--color-canvas-subtle));
  border-bottom: $border-width $border-style var(--borderColor-muted, var(--color-border-muted));

  // stylelint-disable-next-line primer/utilities
  .container {
    position: relative;
  }
}

.billing-manager-banner-text {
  // stylelint-disable-next-line primer/spacing
  margin-left: 210px;
  font-size: $h5-size;
  color: var(--fgColor-muted, var(--color-fg-muted));

  // stylelint-disable-next-line primer/no-override
  .btn {
    margin-top: $spacer-2;
    margin-right: $spacer-2;
  }
}

.billing-manager-banner-title {
  font-size: $h6-size;
  font-weight: $font-weight-bold;
  color: var(--fgColor-muted, var(--color-fg-muted));
}

.billing-manager-icon {
  position: absolute;
  top: -35px;
  left: 0;
  width: 180px;
  height: 180px;
  // stylelint-disable-next-line primer/typography
  font-size: 180px;
  color: var(--fgColor-muted, var(--color-fg-muted));
}
